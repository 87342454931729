import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Footer() {
  const data = useStaticQuery(graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "footer" } }) {
      frontmatter {
        footerLinks {
          heading
          links {
            text
            url
          }
        }
        contactInfo {
          icon
          text
        }
        socialMediaLinks {
          icon
          url
        }
      }
    }
  }
`)


  const { footerLinks, contactInfo, socialMediaLinks } = data.markdownRemark.frontmatter;

  return (

    <footer className="footer">
      <div className="footer__content">
        <div className="footer__links">
          {footerLinks.map(section => (
            <div key={section.heading}>
              <h3 className="footer__heading">{section.heading}</h3>
              <ul>
                {section.links.map(link => (
                  <li key={link.text}>
                    <Link to={link.url}>{link.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="footer__contact">
          <h3 className="footer__heading">Contact Us</h3>
          <ul>
            {contactInfo.map(contact => (
              <li key={contact.icon}>
                {<FontAwesomeIcon size="lg" icon={contact.icon} />}
                {/* <FontAwesomeIcon icon={['fab', contact.icon]} />
                <FontAwesomeIcon icon={['fas', 'coffee']} /> */}
                {contact.text}
              </li>
            ))}
          </ul>
        </div>

        <div className="footer__social">
          <h3 className="footer__heading">Follow Us</h3>
          <ul>
            {socialMediaLinks.map(link => (
              <li key={link.icon}>
                <a href={link.url}>
                  {<FontAwesomeIcon icon={['fab', link.icon]} />}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>

  );
}
